import React from "react";

import {TitleH2, TitleH3, TourCardsContainer, ToursBlockContainer} from "../../styles/Styles";
import TourCard from "./TourCard";
import {EiffelUrl} from "../common/utils";



const ToursBlock = () => {
    const eiffelTours = [{
        title: "Guided tour to the 2nd floor with stairs access",
        description: "Experience the iconic Eiffel Tower like never before with our guided tour to the 2nd floor via stairs access. ",
        price: 60,
        url: EiffelUrl.TOP_LIFT,
        image: "images/eiffel-tower/top-lift/eiffel-tower-top-lift-1.jpg"
    },{
        title: "Guided tour to the Summit with stairs access",
        description: "Embark on an exhilarating journey to the summit of the iconic Eiffel Tower, taking the scenic route via the stairs.",
        price: 55,
        url: EiffelUrl.TOP_STAIRS,
        image: "images/eiffel-tower/top-stairs/eiffel-tower-top-stairs-4.jpg"
    },{
        title: "Guided tour to the 2nd floor with elevator access",
        description: "Elevate your Parisian experience with our guided tour to the 2nd floor of the Eiffel Tower via elevator access. ",
        price: 45,
        url: EiffelUrl.LIFT_2,
        image: "images/eiffel-tower/2-lift/eiffel-tower-2floor-lift-1.jpg"
    },{
        title: "Guided tour to the 2nd floor with stairs access",
        description: "Experience the iconic Eiffel Tower like never before with our guided tour to the 2nd floor via stairs access. ",
        price: 29,
        url: EiffelUrl.STAIRS_2,
        image: "images/eiffel-tower/2-stairs/eiffel-tower-2floor-stairs-6.jpg"
    }]

    const louvreTours = [{
        title: "Guided Louvre Museum Tour with Mona Lisa",
        description: "Embark on an enriching walking guided tour of the Louvre with a local guide who will escort you to see the iconic Mona Lisa and other masterpieces",
        price: 45,
        url: "/louvre-museum",
        image: "images/louvre/louvre-museum-1.jpg"
    }]
    return <ToursBlockContainer>
        <TitleH2>Eiffel Tower Guided Tours</TitleH2>
        <TourCardsContainer>
            {eiffelTours.map((tour, index) => (
                <TourCard
                    key={index}
                    title={tour.title}
                    alt={tour.title}
                    description={tour.description}
                    price={tour.price}
                    url = {tour.url}
                    image={tour.image}
                />
            ))}
        </TourCardsContainer>
        <TitleH2>Louvre museum Guided Tours</TitleH2>
        <TourCardsContainer>
            {louvreTours.map((tour, index) => (
                <TourCard
                    key={index}
                    title={tour.title}
                    alt={tour.title}
                    description={tour.description}
                    price={tour.price}
                    url = {tour.url}
                    image={tour.image}
                />
            ))}
        </TourCardsContainer>
    </ToursBlockContainer>
}

export default ToursBlock;
