import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SliderContainer} from "../styles/Styles";

const SwipeToSlide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true, // Enable auto-scrolling
        autoplaySpeed: 3000, // Change the speed of auto-scrolling (in milliseconds)
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 4,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1128,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 1468,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "20px",
                }
            }
        ]
        /*afterChange: function(index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        }*/
    };
    return (
        <SliderContainer>
            <Slider {...settings}>
                <div>
                    <img src="images/front-page/eiffel-tower-1.jpg" alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-2.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-3.jpg" alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-4.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-5.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-6.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-7.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-8.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-9.jpg"   alt="Eiffel tower"/>
                </div>
                <div>
                    <img src="images/front-page/eiffel-tower-10.jpg"   alt="Eiffel tower"/>
                </div>
            </Slider>
        </SliderContainer>
    );
}

export default SwipeToSlide;
