import React from "react";
import {FaFacebook, FaInstagram, FaWhatsapp} from "react-icons/fa";
import {SocialContainer, TitleH2} from "../../styles/Styles";

const SocialBlock = () => {

    return (<SocialContainer>
        <TitleH2>Follow us in social media</TitleH2>
        <div className="icons">
            <a href="https://www.facebook.com/adventourpariscom" target="_blank" rel="noopener,nofollow"><FaFacebook /></a>
            <a href="https://www.instagram.com/adventourparis/" target="_blank" rel="noopener,nofollow"><FaInstagram /></a>
            <a href="https://wa.me/33666367472" target="_blank" rel="noopener,nofollow"><FaWhatsapp /></a>
          {/*  <a href="" target="_blank" rel="noopener,nofollow"><FaFacebook /></a>
            <a href="" target="_blank" rel="noopener,nofollow"><FaFacebook /></a>*/}

        </div>
    </SocialContainer>);
}

export default SocialBlock;
