import React, {useEffect, useState} from "react";
import {MobileMenuContainer} from "../../styles/Styles";
import {EiffelUrl, Links} from "../common/utils";
import {useLocation} from "react-router-dom";
import {FaBars, FaTimes} from "react-icons/fa";



const HeaderMobileMenu = () => {
    const location = useLocation();
    const [opened, setOpened] = useState(false);

    return <MobileMenuContainer>
        <button onClick={() => setOpened(!opened)}>
            {!opened ? <FaBars size={18} /> : <FaTimes size={18} />}
        </button>

        <div className={`mobile-menu ${opened ? "menu-active" : ""}`}>
           <div>
               <a href={Links.LOUVRE} className={location.pathname === Links.LOUVRE ? "selected" : ""}>Louvre museum</a>
           </div>
           <div>
               <a href={EiffelUrl.TOP_LIFT}  className={location.pathname === Links.EIFFEL ? "selected" : ""}>Eiffel tower</a>
           </div>
            <div>
                <a href={Links.CONTACTS}>Contacts</a>
            </div>
        </div>

    </MobileMenuContainer>
}

export default HeaderMobileMenu;
