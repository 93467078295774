import {Content, TitleH1} from "../styles/Styles";
import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import FooterBlock from "../components/footer/FooterBlock";
import React from "react";

const ConditionsPage = () => {

    return (<>
        <HeaderMenuBlock />
        <Content>
            <TitleH1>Conditions générales de vente </TitleH1>
            <div className="wrapper">
                <p>1. Présentation de l’entreprise Nous proposons des visites guidées des sites les plus emblématiques. Nos clients peuvent sélectionner et réserver les visites directement sur notre site web en utilisant la plateforme de paiement Stripe.</p>
                <p>2. Processus de commande</p>
                <p>2.1 Sélection des services Les clients peuvent parcourir les différentes visites guidées proposées sur notre site web. Pour chaque visite, une description détaillée, les dates disponibles, et les tarifs sont indiqués.</p>
                <p>2.2 Passation de commande Pour réserver une visite guidée, le client doit sélectionner la visite souhaitée, choisir la date et l’heure, et indiquer le nombre de participants. Une fois ces choix effectués, le client ajoute la visite au panier et procède à la commande en cliquant sur le bouton “Réserver maintenant”.</p>
                <p>2.3 Validation de commande Avant la validation de la commande, le client a la possibilité de vérifier les détails de sa réservation, y compris le prix total et les informations sur la visite. Il peut alors apporter les modifications nécessaires. Après confirmation des informations, le client doit fournir ses coordonnées et accepter les présentes Conditions Générales de Ventes.</p>
                <p>2.4 Paiement Le paiement est effectué via la plateforme Stripe. Le client est redirigé vers la page de paiement sécurisée de Stripe où il doit entrer les informations de sa carte bancaire. Le montant total de la réservation est débité au moment de la validation de la commande. Une fois le paiement effectué, le client est redirigé vers notre site web.</p>
                <p>3. Confirmation de commande Après le paiement, une confirmation de commande est envoyée par e-mail au client. Cet e-mail contient les détails de la réservation, y compris la date, l’heure, le nombre de participants, et les informations de contact pour toute question ou modification.</p>
                <p>4. Annulations et remboursements Veuillez consulter notre politique d’annulation et de remboursement pour plus de détails sur les conditions et les délais applicables.</p>
                <p>5. Responsabilité Nous déclinons toute responsabilité en cas d’incidents ou d’imprévus survenus en dehors de notre contrôle, tels que des retards causés par des conditions météorologiques ou des problèmes de transport.</p>
                <p>6. Protection des données personnelles Les informations personnelles des clients sont protégées conformément à notre politique de confidentialité et ne sont utilisées que pour traiter les réservations et communiquer avec les clients.</p>
            </div>
             <FooterBlock/>
        </Content>
    </>);
}

export default ConditionsPage;
