import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import {Content, TitleH1} from "../styles/Styles";
import FooterBlock from "../components/footer/FooterBlock";
import React from "react";

const CookiePage = () => {

    return (<>
        <HeaderMenuBlock />
        <Content>
            <TitleH1>Cookie Policy (EU)</TitleH1>
            <div className="wrapper">
                <p>This Cookie Policy was last updated on May 21, 2024 and applies to citizens and legal permanent residents of the European Economic Area and Switzerland.</p>
                <p>1. Introduction. Our website, https://adventourparis.com (hereinafter: “the website”) uses cookies and other related technologies (for convenience all technologies are referred to as “cookies”). Cookies are also placed by third parties we have engaged. In the document below we inform you about the use of cookies on our website.</p>
                <p>2. What are cookies? A cookie is a small simple file that is sent along with pages of this website and stored by your browser on the hard drive of your computer or another device. The information stored therein may be returned to our servers or to the servers of the relevant third parties during a subsequent visit.</p>
                <p>3. What are scripts? A script is a piece of program code that is used to make our website function properly and interactively. This code is executed on our server or on your device.</p>
                <p>4. What is a web beacon? A web beacon (or a pixel tag) is a small, invisible piece of text or image on a website that is used to monitor traffic on a website. In order to do this, various data about you is stored using web beacons.</p>
                <p>5. Cookies</p>
                <p>5.1 Technical or functional cookies. Some cookies ensure that certain parts of the website work properly and that your user preferences remain known. By placing functional cookies, we make it easier for you to visit our website. This way, you do not need to repeatedly enter the same information when visiting our website and, for example, the items remain in your shopping cart until you have paid. We may place these cookies without your consent.</p>
                <p>5.2 Statistics cookies. We use statistics cookies to optimize the website experience for our users. With these statistics cookies we get insights in the usage of our website. We ask your permission to place statistics cookies.</p>
                <p>7. Consent. When you visit our website for the first time, we will show you a pop-up with an explanation about cookies. As soon as you click on “Save preferences”, you consent to us using the categories of cookies and plug-ins you selected in the pop-up, as described in this Cookie Policy. You can disable the use of cookies via your browser, but please note that our website may no longer work properly.</p>
                <p>7.1 Manage your consent settings</p>
                <p> Functional. The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network. </p>
                <p> Statistics. The technical storage or access that is used exclusively for anonymous statistical purposes. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, information stored or retrieved for this purpose alone cannot usually be used to identify you. </p>
                <p>8. Enabling/disabling and deleting cookies. You can use your internet browser to automatically or manually delete cookies. You can also specify that certain cookies may not be placed. Another option is to change the settings of your internet browser so that you receive a message each time a cookie is placed. For more information about these options, please refer to the instructions in the Help section of your browser.
                Please note that our website may not work properly if all cookies are disabled. If you do delete the cookies in your browser, they will be placed again after your consent when you visit our website again.</p>
                <p>9. Your rights with respect to personal data</p>
                <p>
                    <ul>
                        <li>You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.</li>
                        <li>Right of access: You have the right to access your personal data that is known to us.</li>
                        <li>Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal data whenever you wish.</li>
                        <li>If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.</li>
                        <li>Right to transfer your data: you have the right to request all your personal data from the controller and transfer it in its entirety to another controller.</li>
                        <li>Right to object: you may object to the processing of your data. We comply with this, unless there are justified grounds for processing.</li>
                    </ul>
                </p>
                <p>To exercise these rights, please contact us. Please refer to the contact details at the bottom of this Cookie Policy. If you have a complaint about how we handle your data, we would like to hear from you, but you also have the right to submit a complaint to the supervisory authority (the Data Protection Authority).</p>
                <p>10. Contact details</p>
                <p>For questions and/or comments about our Cookie Policy and this statement, please contact us by using the following contact details:
                    Adventour Paris
                    Rue de Lyon 61, 75012, Paris
                    France
                    Website: https://adventourparis.com
                    Email: info@adventourparis.com</p>
                <p>This Cookie Policy was synchronized with cookiedatabase.org on July 1, 2024</p>
                <p></p>
            </div>
            <FooterBlock/>
        </Content>
    </>);
}

export default CookiePage;
