import {EiffelType, EiffelUrl, urlToTypeMap} from "../common/utils";
import {EiffelMenu} from "../../styles/Styles";
import React from "react";

interface EiffelPageMenuProps {
    currentPage: EiffelType;
    updatePage: (val: EiffelType) => void;
}

const EiffelPageMenu: React.FC<EiffelPageMenuProps> = ({currentPage = "", updatePage }) => {

    return (<EiffelMenu>
        <div>
            <div>
                <a href={EiffelUrl.TOP_LIFT}>
                    <img src="../images/icons/eiffel_tower_top_lift.png"
                         alt=""
                         className={`${currentPage === EiffelType.TOP_LIFT ? "selected" : ""} `}
                    />
                </a>
            </div>
            <div>
                <a href={EiffelUrl.TOP_STAIRS}>
                    <img src="../images/icons/eiffel_tower_top_stairs.png"
                         alt=""
                         className={`${currentPage === EiffelType.TOP_STAIRS ? "selected" : ""} `}
                    />
                </a>
            </div>
            <div>
                <a href={EiffelUrl.LIFT_2}>
                    <img src="../images/icons/eiffel_tower_2_lift.png"
                         alt=""
                         className={`${currentPage === EiffelType.LIFT_2 ? "selected" : ""} `}
                    />
                </a>
            </div>
            <div>
                <a href={EiffelUrl.STAIRS_2}>
                    <img src="../images/icons/eiffel_tower_2_stairs.png"
                         alt={"Eiffel tower tour with stairs access"}
                         className={`${currentPage === EiffelType.STAIRS_2 ? "selected" : ""} `}
                    />
                </a>
            </div>
        </div>
    </EiffelMenu>)
}

export default EiffelPageMenu;
