import React from "react";
import {TitleH1, TitleH2, TitleH3, ToursBlockContainer} from "../../styles/Styles";
import SinglePageGallery from "../SinglePageGallery";
import {EiffelType, LouvreType, StripeLinks, StripeQR} from "../common/utils";
import {FaExternalLinkAlt} from "react-icons/fa";

type TariffsTypeProps = {
    text: string;
    price: number;
}

type TourSingleBlockProps = {
    type?: string;
    overviewText?: string;
    included?: string[];
    tariffs?: TariffsTypeProps[];
    meetingPointImg?: string;
    meetingPointText?: string;
    meetingPointGps?: string;
    bookLink?: string;
    qrCode?: string;
};

const TourSingleBlock: React.FC<TourSingleBlockProps> = (
    { type = "", overviewText = "", included = [], tariffs = [],
        meetingPointImg = "",
        meetingPointGps = "",
        meetingPointText = "",
        bookLink = "",
        qrCode = "",
    }) => {
    const getTourTitle = () => {
        switch(type){
            case EiffelType.TOP_LIFT:
                return "Guided tour to the Summit with elevator access";
            case EiffelType.TOP_STAIRS:
                return "Guided tour to the Summit with stairs access";
            case EiffelType.LIFT_2:
                return "Guided tour to the 2nd floor with elevator access";
            case EiffelType.STAIRS_2:
                return "Guided tour to the 2nd floor with stairs access";
            case LouvreType.MONALIZA:
                return "Louvre Museum Tour with Mona Lisa"
            default: return "Guided Eiffel tower tour";
        }
    }

    return <ToursBlockContainer>
        <TitleH2>{getTourTitle()}</TitleH2>
        <TitleH3>Tour overview</TitleH3>
        <div className="content">
            <div className="content-gallery">
                {type === EiffelType.TOP_LIFT
                    ? <SinglePageGallery baseUrl={"../images/eiffel-tower/top-lift"} filename={"eiffel-tower-top-lift"} imagesNum={7} />
                    : type === EiffelType.TOP_STAIRS
                    ? <SinglePageGallery baseUrl={"../images/eiffel-tower/top-stairs"} filename={"eiffel-tower-top-stairs"} imagesNum={6} />
                    : type === EiffelType.LIFT_2
                    ? <SinglePageGallery baseUrl={"../images/eiffel-tower/2-lift"} filename={"eiffel-tower-2floor-lift"} imagesNum={7} />
                    : type === EiffelType.STAIRS_2
                    ? <SinglePageGallery baseUrl={"../images/eiffel-tower/2-stairs"} filename={"eiffel-tower-2floor-stairs"} imagesNum={9} />
                    : type === LouvreType.MONALIZA
                ? <SinglePageGallery baseUrl={"../images/louvre"} filename={"louvre-museum"} imagesNum={8} />
                : <></>}
            </div>
            <div className="content-text" dangerouslySetInnerHTML={{ __html: overviewText }} />
        </div>
        <TitleH3>Included into activity</TitleH3>
        <div className="content">
            {included.length > 0 && (<ul>
                {included.map((inc, index) => (<li key={index}>{inc}</li>))}
            </ul>)}
        </div>
        <TitleH3>Tariffs</TitleH3>
        <div className="content">
            {tariffs.length > 0 && (<div className="prices">
                {tariffs.map((tarif, index) => (<div key={index}>
                    <span>{tarif.text}</span>
                    <span className="price">{tarif.price}€</span>
                    <a href={bookLink} className="book_link" target={"_blank"} rel={"noopener"}>Book the tour <FaExternalLinkAlt /></a>
                </div>))}
            </div>)}
            <div className="content-text">
                <img src={qrCode} alt="QR code to scan" width={135} height={"auto"} />
            </div>
        </div>
        <TitleH3>Meeting point</TitleH3>
        <div className="content">
            <div className="content-gallery">
                <img src={meetingPointImg} alt="Meeting point" height={200} width={"auto"}/>
            </div>
            <div className="content-text">
                <p>{meetingPointText}</p>
                <p>We kindly ask that you arrive at the meeting point 15 minutes earlier than the tour's start time.</p>
                <p><a href={meetingPointGps} target={"_blank"} rel={"noopener"}>Google map location <FaExternalLinkAlt size={10} /></a></p>
            </div>
        </div>
        <TitleH3>Cancellation policy</TitleH3>
        <div className="content">
            <ul className="">
                <li>You can cancel up to 24 hours in advance of the experience for a full refund.</li>
                <li className="feature__ojl8">
                    <div>If you cancel less than 24 hours before the experience’s start time, the amount you paid will
                        not be refunded.
                    </div>
                </li>
                <li className="feature__ojl8">
                    <div>Any changes made less than 24 hours before the experience’s start time will not be accepted.
                    </div>
                </li>
                <li>Cut-off times are based on the experience’s local time</li>
                <li className="feature__ojl8">
                    <div>This experience requires good weather. If it’s canceled due to poor weather, you’ll be offered
                        a different date or a full refund.
                    </div>
                </li>
                <li className="feature__ojl8">
                    <div>This experience requires a minimum number of travelers. If it’s canceled because the minimum
                        isn’t met, you’ll be offered a different date/experience or a full refund.
                    </div>
                </li>
            </ul>
        </div>
    </ToursBlockContainer>
}

export default TourSingleBlock;
