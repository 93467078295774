import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SinglePageGallery = ({ baseUrl = "", filename = "", imagesNum = 0 }) => {
    const settings = {
        customPaging: function(i: any) {
            return (
                <a>
                    <img src={`${baseUrl}/${filename}-${i + 1}.jpg`} width={"60px"} height={"auto"} />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    const images = Array.from({ length: imagesNum }, (_, index) => (
        <img key={index} src={`${baseUrl}/${filename}-${index + 1}.jpg`} alt={`Image ${index + 1}`} />
    )) || [];

    return (<div className="slider-container">
        <Slider {...settings}>
            {images.map((image, index) => (<div key={index}>{image}</div>))}
        </Slider>
    </div>)
}

export default SinglePageGallery;
