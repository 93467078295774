import {HeaderMenu} from "../../styles/Styles";
import React, {useRef, useState} from "react";
import Modal from 'react-modal';
import {EiffelUrl, Links, MenuPages} from "../common/utils";
import {useLocation} from "react-router-dom";
import HeaderMobileMenu from "./HeaderMobileMenu";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const HeaderMenuBlock = () => {
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);


    return (<>
            <HeaderMobileMenu />
            <HeaderMenu>
                <div className="logo">
                    <a href={"/"}><img src="../images/logo-adventour-paris.png" alt="Adventour Paris"/></a>
                </div>
                <div className="top-buttons">
                    <a href={Links.LOUVRE} className={location.pathname === Links.LOUVRE ? "selected" : ""}>Louvre museum</a>
                    <a href={EiffelUrl.TOP_LIFT}  className={location.pathname === Links.EIFFEL ? "selected" : ""}>Eiffel tower</a>
                </div>
                {/*<div className="reserve" onClick={() => setIsOpen(true)}>Book now</div>*/}
            </HeaderMenu>
            {isOpen && <Modal isOpen={isOpen} style={customStyles} onRequestClose={() => setIsOpen(false)}><div>Lorem ipsum dolor.</div></Modal>}
    </>
    )
}

export default HeaderMenuBlock;
