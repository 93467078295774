import React from "react";
import { useNavigate } from 'react-router-dom';
import {ButtonRight, Card, CardText, TitleH3} from "../../styles/Styles";
import {FaChevronRight} from "react-icons/fa";

const TourCard = ({
        image = "images/header-background.png",
        alt="Guided Eiffel tower tour",
        title= "Guided Eiffel tower tour",
        description = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, saepe?",
        price = 0,
        url = ""
    }) => {
    const navigate = useNavigate();

    return <Card>
        <img src={image} alt={alt} onClick={() => navigate(url)} />
        <div className="price">from <span>{`${price}€`}</span></div>
        <TitleH3>{title}</TitleH3>
        <CardText>{description}</CardText>
        <ButtonRight>See detailed <FaChevronRight /></ButtonRight>
    </Card>
}

export default TourCard;
