import styled from "styled-components";

/**
 *
 * Body
 *
 */


export const Container = styled.div`
  overflow-x: auto;
  background: #ebf1fc;
  height: 100%;
  p {
    margin-bottom: 1em;
  }
`;

/**
 *
 * Header
 *
 */
export const Header = styled.div`
  margin: 2em auto;
  width: 90%;
  height: 100vh;
  //background: url("images/header-background.png") center no-repeat #ccc;
  //background-size: cover;
  border-radius: 10px;
  padding: 1.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  
  .header-menu--wrapper {
    max-width: 768px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 640px) {
      width: 90%;
    }
  }
  
  .title-text {
    font-family: "Nunito", sans-serif;
    font-size: 4em;
    line-height: 1em;
    font-weight: bolder;
    text-space: 20px;
    color: #fff;
    margin-top: 2em;
    margin-left: 2em;
    max-width: 640px;
    width: 100%;

    @media (max-width: 1023px) {
      font-size: 3em;
    }

    @media (max-width: 767px) {
      font-size: 2.8em;
      margin-left: 1.5em;
    }

    @media (max-width: 639px) {
      font-size: 2em;
      margin-left: 1.2em;
    }
    @media (max-width: 375px) {
      font-size: 1.5em;
      line-height: 1.9em;
    }
    
    & > span {
      font-family: "PT Sans", sans-serif;
      text-transform: uppercase;
      font-size: 2.0em;
      line-height: 0.9em;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      margin-top: 0.5em;

      @media (max-width: 639px) {
        font-size: 1.7em;
        line-height: 1.2em;
        padding: 0 1em 0 0;
      }

    }
    .phone > a {
      font-size: 0.4em;
      display: inline;
      color: aliceblue;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s ease;

      @media (max-width: 639px) {
        font-size: 1em;
        line-height: 1.6em;
        padding-top: 1em;
        padding-right: 1em;
      }

      @media (max-width: 375px) {
        font-size: .9em;
        line-height: 1.5em;
        padding-top: 1em;
      }
      
      &:hover {
        border-bottom: 1px solid aliceblue;
      }
    }
  }
`;

export const MobileMenuContainer = styled.div`
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  padding: .4em 0 0.3em 0.4em;
  z-index: 9999;
  @media (min-width: 641px) {
    display: none;
  }
  
  & > button {
    margin: 0.5em 0.5em 0em auto;
    padding: 0.3em;
    &:hover {
      cursor: pointer;
    }
  }
  & > nav > ul {
    background: #fff;
    padding: 2em 0 2em 3em;
  }
  & > .mobile-menu {
    width: 0;
    height: 0;
    background: #fff;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    top: 50px;
    left: 0;
    transition: width 0.3s ease-in, height 0.3s ease-in;
    padding-top: 2em;
    
    & > div  {
     padding: 0.6em 1em;
      margin-bottom: 0.4em;
      color: #222;
      & > a {
        color: #222;
        font-size: 1.3em;
        text-decoration: none;
      }
      &:hover {
        border-radius: 15px;
        background: #ccc;
        margin-left: 0.5em;
        margin-right: 0.5em;
        transition: all 0.3s ease-in;
      }
    }
  }
  & > .menu-active {
    height: 100vh;
    width: 250px;   
    overflow: auto;
    transition: width 0.3s ease-in, height 0.3s ease-in;
  }
`;

export const HeaderMenu = styled.div`
  border-radius: 30px;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 0.5em;
  margin: 1em auto;
  @media (max-width: 640px) {
    margin-top: 4em;
  }

  & > .logo {
    height: 40px;
    width: 27px;
    margin: 0 1em;
    padding: 0.3em;

    & > a > img {
        width: 100%;
        height: 100%;
      }
  }

  & > .reserve {
    background: #D6AC81;
    border-radius: 30px;
    padding: 1em 1em;

    & > a {
      color: aliceblue;
      padding: 0.3em;
      text-decoration: none;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
    }
  }

  & > .top-buttons {
    color: #8c8c8c;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 600px;

    & > a {
      display: block;
      color: #333;
      font-size: 1.1em;
      text-decoration: none;
      padding: 1em 1.5em;
      border-radius: 30px;

      &:hover,
      &.selected{
        font-weight: bold;
        background: #ccc;
        cursor: pointer;
        transition: 0.3s ease-in;
      }
    }

    @media (max-width: 640px) {
      display: none;
    }
  }
`;

/**
 *
 * Titles
 *
 */

export const TitleH1 = styled.h1`
  font-size: 3.5em;
  line-height: 1.5em;
  text-align: center;
  font-weight: bold;
  color: black;
  padding: 0.5em 1em;

  @media (max-width: 640px) {
    font-size: 2em;
  }
`;

export const TitleH2 = styled.h2`
  font-size: 2.5em;
  line-height: 1em;
  text-align: center;
  font-weight: bold;
  color: black;
  padding: 0.5em 1em;

  @media (max-width: 640px) {
    font-size: 1.7em;
  }
`;

export const TitleH3 = styled.h3`
  font-size: 1.3em;
  line-height: 1em;
  font-weight: bold;
  color: black;
  padding: 0.2em;
  margin: 0.2em;
`;

/**
 *
 * Content
 */

export const Content = styled.div`
  position: relative;
  z-index: 1; // Ensure content is above the video
  padding: 0 4em;
  color: white;
  text-align: center;
  max-width: 1568px;
  margin: 0 auto;

  @media (max-width: 639px) {
    padding: 0 1em;
  }
  
  & p {
    color: #222;
    text-align: left;
  }
  & > .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1em 0 2em 0;
  }
  a {
    color: #000;
    text-decoration: underline;
    &:hover {
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
`;

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BackgroundVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const SocialContainer = styled.div`
  background: #fff;
  padding: 1em 2em 4em 2em;
  margin: 0;
  
  & > .icons {
    display: flex;
    justify-content: center;
    gap: 2em;
    
    & > a > svg {
      color: #CD6F35;
      width: 50px;
      height: auto;
    }
  }
`;


/**
 *
 * Tours
 *
 */

export const ToursBlockContainer = styled.div`
  border-radius: 0;
  background: #F6F0E0;
  padding: 0.9em;
  & > .content {
    display: flex;
    justify-content: start;
    margin-bottom: 2em;

    @media (max-width: 640px) {
      flex-direction: column-reverse;
    }
    
    ul {
      list-style: disc;
      color: #222;
      & > li {
        text-align: left;
      }
    }
    .prices {
      display: flex;
      gap: 1em;
      @media (max-width: 1024px) {
        flex-wrap: wrap;
      }
      
      & > div {
        border: 1px solid #CCC;
        border-radius: 7px;
        display: flex;
        font-size: 1.3em;
        line-height: 1.1em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #222;
        width: 150px;
        height: 150px;
        padding-top: .5em;
        & > .price {
          font-size: 2em;
          font-weight: bold;
          margin-top: 0.5em;
        }
        .book_link {
          display: block;
          margin-top: auto;
          margin-bottom: 0.5em;
          background: #D6AC81;
          color: #222;
          border-radius: 5px;
          font-size: 0.8em;
          padding: 0.2em 0.5em;
          text-decoration: none;
          &:hover {
            box-shadow: #ccc 2px 2px;
          }
        }
      }
    }

    & > .content-text {
      font-size: 1.1em;
      line-height: 1.2em;
      color: #333;
      text-align: left;
      padding: 0 2em;
      width: 60%;
      
      @media (max-width: 640px) {
        width: 100%;
        padding: 0;
      }
    }
    & > .content-gallery {
      width: 40%;
      @media (max-width: 640px) {
        width: 100%;
        margin: 1em 0 2em 0;
      }
      
      & img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const TourCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-evenly;
  gap: 1.5em;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  border-radius: 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 270px;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    max-width: 400px;
  }
  
  & > .price {
    font-size: .9em;
    border-radius: 20px;
    border: 1px solid #ccc;
    background: #fff;
    color: #333;
    margin: 0.5em;
    padding: 0.2em 0.5em;
    align-self: end;
    & > span {
      font-weight: bold;
    }
  }
  
  & > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const CardText = styled.div`
  font-size: 0.9em;
  color: #333;
  line-height: 1em;
  text-align: left;
  padding: 0 1em 0.7em;
  overflow-wrap: break-word;
  margin-bottom: 0.5em;
`;

export const ButtonRight = styled.div`
  margin: 0.5em 0.5em 0.5em auto;
  background: #7B3E17;
  border-radius: 15px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  text-align: center;
  font-size: 1.1em;
  line-height: 1.3em;
  padding: 0.3em 1em;
  
  &:hover {
    cursor: pointer;
    scale: 1.05;
    transition: 0.5s;
  }
`;

export const EiffelMenu = styled.div`
 
  overflow: hidden;
  margin: 0.5em auto;
  overflow-x: scroll;
  padding: 1em 0;
  & > div {
    width: 1000px;
    display: flex;
    justify-content: space-evenly;

    & > div {
      cursor: pointer;
      max-width: 170px;
      @media (max-width: 640px) {
        max-width: 140px;
      }

      & > a > img {
        height: auto;
        width: 100%;
        &:hover,
        &.selected {
          outline: 5px darkred dotted;
          transition: 0.2s ease-in all;
          border-radius: 50%;
          text-decoration: none;
          border-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: 640px) {
    gap: 0.4em;
  }
`;

/**
 *
 * Slider
 */


export const SliderContainer = styled.div`
  display: block;
  margin: 0 auto 3em auto;
  
  
img {
  border-radius: 15px;
  width: 100%;
  max-width: 320px;x
  height: auto;
}
  
`;

/**
 *
 * Footer
 */

export const FooterContainer = styled.div`
  background: #7B3E17;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2em auto;
  padding: 2em;
  
  @media (max-width: 639px) {
    flex-direction: column;
    justify-content: left;
  }
  
  & > .text {
    font-size: 1em;
    line-height: 1.3em;
  }
  & > .links {
    //display: flex;
    //flex-direction: column;
    a {
      display: inline;
      color: aliceblue;
      text-align: left;
      margin-bottom: 1em;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s ease;
      
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
`;
