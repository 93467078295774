import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import LouvreMuseumPage from "../../pages/LouvreMuseumPage";
import EiffelTowerPage from "../../pages/EiffelTowerPage";
import FrontPage from "../../pages/FrontPage";
import ConditionsPage from "../../pages/ConditionsPage";
import CookiePage from "../../pages/CookiePage";
import {Links} from "../common/utils";
import Contacts from "../../pages/Contacts";

const AppRouter = () => {
    return (
        <Routes>
            <Route path={Links.FRONT} element={<FrontPage />} />
            <Route path={Links.LOUVRE} element={<LouvreMuseumPage />} />
            <Route path={Links.EIFFEL} element={<EiffelTowerPage />} />
            <Route path={`${Links.EIFFEL}/:pageId`} element={<EiffelTowerPage />} />
            <Route path={Links.CONDITIONS} element={<ConditionsPage />} />
            <Route path={Links.CONTACTS} element={<Contacts />} />
            <Route path={Links.COOKIE} element={<CookiePage />} />
    </Routes>
);
};

export default AppRouter;
