export enum StripeLinks {
    SUMMIT_LIFT = "https://book.stripe.com/6oEdRYcdp2oyb3WeVc",
    SUMMIT_STAIRS = "https://buy.stripe.com/7sI15c5P11ku3Bu3cw",
    STAIRS_2 = "https://book.stripe.com/fZebJQ5P11ku7RKcN7",
    LIFT_2 = "https://book.stripe.com/eVa6pw3GT5AK7RKeVg",
    MONALISA = "https://book.stripe.com/7sI5ls91d4wGb3W3cA",
}

export enum StripeQR {
    SUMMIT_LIFT = "../images/qr/qr_summit_lift.png",
    SUMMIT_STAIRS = "../images/qr/qr_summit_stairs.png",
    LIFT_2 = "../images/qr/qr_2_lift.png",
    STAIRS_2 = "../images/qr/qr_2_stairs.png",
    MONALISA = "../images/qr/qr_monaliza.png",
    LOUVRE = "../images/qr/qr_louvre_tour.png"
}

export enum MeetingPoint {
    EIFFEL = "Your tour guide will be stationed in front of the first black obelisk with \"Entrance #2\" label, identifiable by the Adventour Paris flag.",
    EIFFEL_GPS = "https://maps.app.goo.gl/dhXaxjJH8k2Jcifh6",
    EIFFEL_IMG = "../images/eiffel-tower/eiffel-tower-meeting-point.png",
    LOUVRE = "Your tour guide will be stationed next to the Arc de Triomphe du Carrousel, identifiable by the Adventour Paris flag.",
    LOUVRE_GPS = "https://maps.app.goo.gl/9GBS12W3osS42qXy9",
    LOUVRE_IMG = "../images/louvre/meeting-point-louvre.png"
}

export enum EiffelType {
    TOP_LIFT= "TOP_LIFT",
    TOP_STAIRS = "TOP_STAIRS",
    LIFT_2 = "LIFT_2",
    STAIRS_2 = "STAIRS_2"
}

export enum EiffelUrl {
    STAIRS_2 = "/eiffel-tower/climbing-stairs-2nd-floor",
    LIFT_2 = "/eiffel-tower/elevator-2nd-floor",
    TOP_STAIRS = "/eiffel-tower/summit-lift-2nd-floor-climbing",
    TOP_LIFT = "/eiffel-tower/summit-lift-2nd-floor-lift",
}

export const urlToTypeMap: { [key in EiffelUrl]: EiffelType } = {
    [EiffelUrl.STAIRS_2]: EiffelType.STAIRS_2,
    [EiffelUrl.LIFT_2]: EiffelType.LIFT_2,
    [EiffelUrl.TOP_STAIRS]: EiffelType.TOP_STAIRS,
    [EiffelUrl.TOP_LIFT]: EiffelType.TOP_LIFT,
};

export enum MenuPages {
    EIFFEL = "EIFFEL",
    LOUVRE = "LOUVRE"
}

export enum Links {
    EIFFEL = "/eiffel-tower",
    LOUVRE = "/louvre-museum",
    CONDITIONS = "/conditions-generales-de-vente",
    COOKIE = "/cookie-policy-eu",
    CONTACTS = "/contacts",
    FRONT = "/"
}

export enum LouvreType {
    MONALIZA = "MONALIZA",
    LOUVRE = "LOUVRE"
}
