import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import {Content, TitleH1} from "../styles/Styles";
import FooterBlock from "../components/footer/FooterBlock";
import React from "react";

const Contacts = () => {

    return (<>
        <HeaderMenuBlock />
        <Content>
            <TitleH1>Contacts</TitleH1>
            <div className="wrapper">
                <p><b>Legal information</b></p>
                <p>Name : Adventour Paris</p>
                <p>Address : 61 rue de Lyon 75012 PARIS</p>
                <p>SIRET : 92823428500015</p>
                <p><b>Contact info</b></p>
                <p>Email : <a href="mailto:info@adventourparis.com">info@adventourparis.com</a></p>
                <p>Phone/WhatsApp : <a href="tel:+33666367472">+33 6 66 36 74 72</a></p>
            </div>
            <FooterBlock/>
        </Content>
    </>)
}

export default Contacts;
