import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import {Content, TitleH1} from "../styles/Styles";
import TourSingleBlock from "../components/tours/TourSingleBlock";
import FooterBlock from "../components/footer/FooterBlock";
import {EiffelType, EiffelUrl, MeetingPoint, StripeLinks, StripeQR, urlToTypeMap} from "../components/common/utils";
import EiffelPageMenu from "../components/header/EiffelPageMenu";

const EiffelTowerPage = () => {
    const { pageId } = useParams();
    const [currentPage, setCurrentPage] = useState(EiffelType.TOP_LIFT);

    const updatePageHandler = (value: any) => {
        setCurrentPage(value);
    }

    useEffect(() => {
        const pageTypes: string[] = Object.values(EiffelUrl);
        const eiffelUrl = `/eiffel-tower/${pageId}`;
        if (pageTypes.includes(eiffelUrl)){
            const eiffelType = urlToTypeMap[eiffelUrl as EiffelUrl];
            setCurrentPage(eiffelType);
        }
    }, [pageId])

    return (<>
        <HeaderMenuBlock />
        <Content>
            <TitleH1>Eiffel Tower guided tours in English, French, Spanish</TitleH1>
            <EiffelPageMenu currentPage={currentPage} updatePage={(val: any) => updatePageHandler(val)} />
            {currentPage === EiffelType.TOP_LIFT && (
                <TourSingleBlock
                    type={EiffelType.TOP_LIFT}
                    tariffs={
                        [
                            {text: "Adult access (25+)", price: 80},
                            {text: "Youth access (12-24yo)", price: 70},
                            {text: "Child access (4-11yo)", price: 60},
                            {text: "Baby access (0-3yo)", price: 0}
                        ]
                    }
                    bookLink={StripeLinks.SUMMIT_LIFT}
                    qrCode={StripeQR.SUMMIT_LIFT}
                    meetingPointImg={MeetingPoint.EIFFEL_IMG}
                    meetingPointText={MeetingPoint.EIFFEL}
                    meetingPointGps={MeetingPoint.EIFFEL_GPS}
                    included={[
                        "Eiffel Tower Tickets for the 2nd and Top floors by elevator",
                        "All Fees and Taxes", "English / French / Spanish / Italian / Serbian speaking guide",
                        "Duration of the tour 1,5-2 hours",
                        "Non-private group of guests"
                    ]}
                    overviewText={"<p>Experience the enchantment of Paris from the best vantage point with our Eiffel Tower Summit Tour!</p>" +
                        "<p>Picture yourself bypassing the long lines and embarking on a journey filled with history, architecture, and stunning vistas. As you meet your expert guide, excitement builds as you catch your first sight of the Eiffel Tower across the Seine River, its grand silhouette standing tall against the Parisian skyline.</p>" +
                        "<p>You ascend the iconic tower, gaining fascinating insights about Gustave Eiffel’s creation along the way. Your guide unveils the tower’s secrets, from its bold construction in 1889 to its role as a symbol of Parisian pride and resilience.</p>" +
                        "<p>Upon reaching the summit, prepare to be captivated by panoramic views that stretch as far as the eye can see. From the shimmering waters of the Seine to the magnificence of the Champs-Élysées, every viewpoint offers a fresh perspective on the City of Light.</p>"}
                />
            )}
            {currentPage === EiffelType.TOP_STAIRS && (
                <TourSingleBlock
                    type={EiffelType.TOP_STAIRS}
                    tariffs={
                        [
                            {text: "Adult access (25+)", price: 65},
                            {text: "Youth access (12-24yo)", price: 60},
                            {text: "Child access (4-11yo)", price: 55},
                            {text: "Baby access (0-3yo)", price: 0}
                        ]
                    }
                    bookLink={StripeLinks.SUMMIT_STAIRS}
                    qrCode={StripeQR.SUMMIT_STAIRS}
                    meetingPointImg={MeetingPoint.EIFFEL_IMG}
                    meetingPointText={MeetingPoint.EIFFEL}
                    meetingPointGps={MeetingPoint.EIFFEL_GPS}
                    included={[
                        "Eiffel Tower Tickets for the 2nd floor by stairs and Top floor by elevator",
                        "All Fees and Taxes", "English / French / Spanish / Italian / Serbian speaking guide",
                        "Duration of the tour 1,5-2 hours",
                        "Non-private group of guests"
                    ]}
                    overviewText={"<p>Embark on an exhilarating journey to the summit of the iconic Eiffel Tower, taking the scenic route via the stairs. This guided tour offers an intimate and active exploration of one of the world’s most famous landmarks, allowing you to experience the Tower from a unique perspective.</p>" +
                        "<p>Starting at the base, your expert guide will lead you through the tower’s history, sharing fascinating insights and stories as you ascend. Climbing the stairs offers an unparalleled opportunity to appreciate the architectural marvel of the Eiffel Tower up close. You'll pass through the first and second levels, each offering stunning panoramic views of Paris and its many landmarks.</p>" +
                        "<p>Feel the excitement build as you continue upwards, with each step bringing you closer to the summit. At the top, you’ll be rewarded with breathtaking 360-degree views of the City of Light, making every effort worthwhile.</p>" +
                        "<p>This tour is perfect for those seeking an active adventure combined with a deep dive into Parisian history and culture. Whether you're a first-time visitor or a seasoned traveler, the stairway to the summit offers a memorable and unique experience that few can claim.</p>"}
                />
            )}
            {currentPage === EiffelType.LIFT_2 && (
                <TourSingleBlock
                    type={EiffelType.LIFT_2}
                    tariffs={
                        [
                            {text: "Adult access (25+)", price: 55},
                            {text: "Youth access (12-24yo)", price: 50},
                            {text: "Child access (4-11yo)", price: 45},
                            {text: "Baby access (0-3yo)", price: 0}
                        ]
                    }
                    bookLink={StripeLinks.LIFT_2}
                    qrCode={StripeQR.LIFT_2}
                    meetingPointImg={MeetingPoint.EIFFEL_IMG}
                    meetingPointText={MeetingPoint.EIFFEL}
                    meetingPointGps={MeetingPoint.EIFFEL_GPS}
                    included={[
                        "Eiffel Tower Tickets for the 2nd floor by elevator",
                        "All Fees and Taxes", "English / French / Spanish / Italian / Serbian speaking guide",
                        "Duration of the tour 1,5-2 hours",
                        "Non-private group of guests"
                    ]}
                    overviewText={"<p>Elevate your Parisian experience with our guided tour to the 2nd floor of the Eiffel Tower via elevator access. Skip the climb and glide effortlessly to one of the most iconic viewpoints in the world. As you ascend, enjoy a seamless journey while our expert guide shares captivating stories about the tower's construction, its historical significance, and the genius of Gustave Eiffel.</p>" +
                        "<p>Upon reaching the 2nd floor, you'll be treated to breathtaking panoramic views of Paris, including landmarks such as the Seine River, the Champs-Élysées, and the Sacré-Cœur. Our guide will walk you through the history and significance of these sights, ensuring you gain a deeper appreciation of the City of Light.</p>" +
                        "<p>This tour is perfect for those who prefer a more relaxed visit while still wanting to experience the majesty of the Eiffel Tower. With a blend of comfort, stunning views, and rich historical insights, this tour offers a memorable and stress-free way to explore one of the world’s most famous landmarks.</p>"
                        }
                />
            )}
            {currentPage === EiffelType.STAIRS_2 && (
                <TourSingleBlock
                    type={EiffelType.STAIRS_2}
                    tariffs={
                        [
                            {text: "Adult access (25+)", price: 41},
                            {text: "Youth access (12-24yo)", price: 39},
                            {text: "Child access (4-11yo)", price: 29},
                            {text: "Baby access (0-3yo)", price: 0}
                        ]
                    }
                    meetingPointImg={MeetingPoint.EIFFEL_IMG}
                    meetingPointText={MeetingPoint.EIFFEL}
                    meetingPointGps={MeetingPoint.EIFFEL_GPS}
                    bookLink={StripeLinks.STAIRS_2}
                    qrCode={StripeQR.STAIRS_2}
                    included={[
                        "Eiffel Tower Tickets for the 2nd floor by stairs",
                        "All Fees and Taxes", "English / French / Spanish / Italian / Serbian speaking guide",
                        "Duration of the tour 1,5-2 hours",
                        "Non-private group of guests"
                    ]}
                    overviewText={"<p>Experience the iconic Eiffel Tower like never before with our guided tour to the 2nd floor via stairs access. This immersive journey begins as you ascend the tower's 674 steps, offering unparalleled views of Paris from different heights. Along the way, our expert guide will share fascinating insights into the tower's history, architecture, and the vision of Gustave Eiffel.</p>" +
                        "<p>As you climb, you'll have the opportunity to pause at various levels, capturing breathtaking photos of landmarks like the Seine River, Notre-Dame Cathedral, and the Louvre. Once on the 2nd floor, enjoy panoramic views of the city while learning about the significance of the Eiffel Tower in French culture and history.</p>" +
                        "<p>This tour is perfect for those who appreciate a more active exploration and wish to experience the Eiffel Tower in a unique, up-close way. The combination of physical activity, stunning views, and rich storytelling makes this an unforgettable Parisian adventure.</p>"
                    }
                />
            )}
            <FooterBlock />
        </Content>
        </>)
}

export default EiffelTowerPage;
