import React from 'react';
import ToursBlock from "./components/front-page/ToursBlock";
import {Container, Content, Header, HeaderMenu} from "./styles/Styles";
import AppRouter from "./components/routers/AppRouter";
import {BrowserRouter, Router} from "react-router-dom";
import {Helmet} from "react-helmet";


const App = () => {
    return (
        <Container>
            <BrowserRouter>
                <Helmet>
                    <title>Guided tours in Paris | Eiffel tower, Louvre Museum</title>
                    <meta name="description" content="Guided professional tours forindividuals and groups in Paris (English, German, French)" />
                </Helmet>
                <AppRouter />
            </BrowserRouter>
        </Container>
    );
}

export default App;
