import React from "react";
import { Parallax } from 'react-parallax';
import {Content, Header, TitleH2} from "../styles/Styles";
import ToursBlock from "../components/front-page/ToursBlock";
import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import SwipeToSlide from "../components/SwipeToSlide";
import SocialBlock from "../components/front-page/SocialBlock";
import FooterBlock from "../components/footer/FooterBlock";
import HeaderMobileMenu from "../components/header/HeaderMobileMenu";
import {FaPhoneAlt, FaWhatsapp} from "react-icons/fa";

const FrontPage = () => {

    return <>
       {/*<Header>
            <HeaderMenuBlock />
           <div className="title-text">Explore with <br /><span>Adventour Paris</span></div>

        </Header>*/}
        <Header>
            <Parallax
                bgImage="images/header-background.png"  // Replace with your image URL
                strength={500}  // Adjust the strength for the parallax effect
                style={{ height: '90vh', borderRadius: "20px 20px 0 0" }}
            >
                <div style={{
                    height: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    //alignItems: 'center',
                    //justifyContent: 'center',
                    color: 'white',
                    //textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
                }}>
                    <div className="header-menu--wrapper">
                        <HeaderMenuBlock />
                    </div>
                    <div className="title-text">Explore with <br />
                        <span>Adventour Paris</span>
                        <div className="phone"><a href="tel:+33666367472"><FaWhatsapp /> <FaPhoneAlt /> +33(0)66.63.67.472</a></div>
                    </div>
                </div>
            </Parallax>
        </Header>

        <Content>
            <ToursBlock />
            <div>
                <TitleH2>Beauty of Paris</TitleH2>
              <SwipeToSlide />
            </div>
            <SocialBlock />
            <FooterBlock/>
        </Content>
    </>
}

export default FrontPage;
