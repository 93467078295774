import React from "react";
import HeaderMenuBlock from "../components/header/HeaderMenuBlock";
import {Content, EiffelMenu, TitleH1} from "../styles/Styles";
import {EiffelType, LouvreType, MeetingPoint, StripeLinks, StripeQR} from "../components/common/utils";
import TourSingleBlock from "../components/tours/TourSingleBlock";

const LouvreMuseumPage = () => {
    return (<>
        <HeaderMenuBlock />
        <Content>
            <TitleH1>Louvre Museum Tour with Mona Lisa</TitleH1>
            <TourSingleBlock
                type={LouvreType.MONALIZA}
                tariffs={
                    [
                        {text: "Adult access (18+)", price: 65},
                        {text: "Child access", price: 45},
                    ]
                }
                meetingPointImg={MeetingPoint.LOUVRE_IMG}
                meetingPointText={MeetingPoint.LOUVRE}
                meetingPointGps={MeetingPoint.LOUVRE_GPS}
                bookLink={StripeLinks.MONALISA}
                qrCode={StripeQR.MONALISA}
                included={[
                    "Louvre museum entrance tickets",
                    "All Fees and Taxes",
                    "English / French / Serbian speaking guide",
                    "Duration of the tour 1-1,5 hours",
                    "Non-private group of guests"
                ]}
                overviewText={"<p>Embark on an enriching walking guided tour of the Louvre with a local guide who will escort you to see the iconic Mona Lisa and other masterpieces. This tour is designed to provide a comprehensive and immersive experience, offering both historical insights and fascinating anecdotes about the world's largest art museum.</p>" +
                    "<p>Introduction and Exterior Tour: Begin your journey outside the Louvre with an overview of its rich history and its role as a former royal palace. Your guide will highlight the architectural marvels of the Louvre's exterior, including the iconic glass pyramid designed by I. M. Pei, and provide fascinating details about its transformation from a medieval fortress to the grand palace it is today. </p>" +
                    "<p>Entrance and Interior Exploration: As you walk through the grand halls, your guide will lead you to various key works of art, offering detailed commentary on each piece. Highlights include: Mona Lisa: Marvel at Leonardo da Vinci's masterpiece and learn about its intriguing history and the mystery surrounding it. Classical Antiquities: Explore the ancient Greek and Roman sculptures, including the Venus de Milo and Winged Victory of Samothrace. Your guide will delve into the historical context and artistic significance of these timeless pieces. </p>"
                }
            />
        </Content>
    </>);
}

export default LouvreMuseumPage;
