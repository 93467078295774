import React from "react";
import {FooterContainer} from "../../styles/Styles";
import {FaRegCopyright} from "react-icons/fa";
import {Links} from "../common/utils";

const FooterBlock = () => {

    return <FooterContainer>
        <div className="text">
            <FaRegCopyright /> Adventour Paris
        </div>
        <div className="text links">
            <p><a href={Links.CONTACTS}>Contacts</a></p>
            <p><a href={Links.CONDITIONS}>Conditions generales de vente</a></p>
            <p><a href={Links.COOKIE}>Cookie policy</a></p>
        </div>
    </FooterContainer>
}

export default FooterBlock;
